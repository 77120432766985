import { useQuery } from '@apollo/client'
import { ArrowBack } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Loader from '../../../../../components/Loader'
import { SubscriptionDocument, UserProfileDocument, UserProfileFragmentFragmentDoc } from '../../../../../graphql/generated'
import cache from '../../../../../providers/apollo/cache'
import Plans from '../../../../conversion/components/plans/Plans'
import useAlerts from '../../../hooks/useAlerts'
import PlanDetails from './PlanDetails'
import { MembershipView } from './types'

export default function Membership() {
  const [view, setView] = useState<MembershipView>(MembershipView.PLAN_DETAILS)
  const { loading, data, refetch } = useQuery(UserProfileDocument)
  const userProfile = data?.userProfile
  const isNoPlan = userProfile?.plan?.plan_name === 'no-plan'
  const plan = userProfile?.plan?.plan_name.split('-')[0].toLowerCase() ?? undefined
  const planCycle = userProfile?.plan?.plan_name.split('-')[1].toLowerCase() ?? undefined
  const refetchRef = useRef(refetch)
  const { createAlert } = useAlerts()

  const userId = getAuth().currentUser?.uid
  if (!userId) {
    createAlert('There was an issue with your session. Please refresh the page and try again.', 'error')
  }
  const profile = cache.readFragment({
    id: `UserProfile:${userId}`,
    fragmentName: 'UserProfileFragment',
    fragment: UserProfileFragmentFragmentDoc
  })

  const subscriptionId = profile?.subscription?.id
  const subscription = useQuery(SubscriptionDocument, { skip: !subscriptionId, variables: { subscriptionId: subscriptionId! } })

  const pendingPlan = subscription.data?.subscriptions.subscription?.recurlySubscription?.pendingChange?.plan?.code ?? undefined
  const recurlySubscription = subscription.data?.subscriptions.subscription?.recurlySubscription ?? undefined

  useEffect(() => {
    const debouncedRefetch = debounce(refetchRef.current, 3000)
    const handleFocus = () => {
      if (document.visibilityState === 'visible') {
        debouncedRefetch()
      }
    }

    // register the event listener
    document.addEventListener('visibilitychange', handleFocus)

    // deregister the event listener
    return () => {
      document.removeEventListener('visibilitychange', handleFocus)
    }
  }, [])

  useEffect(() => {
    if (isNoPlan) {
      setView(MembershipView.CHANGE_PLANS)
    }
  }, [loading, userProfile, isNoPlan])

  let typography

  switch (true) {
    case view === MembershipView.PLAN_DETAILS:
      typography = 'Plan Details'
      break
    case isNoPlan:
      typography = 'Purchase Plan'
      break
    default:
      typography = ''
  }

  const onClick = () => setView(MembershipView.PLAN_DETAILS)
  const openChangePlan = () => {
    setView(MembershipView.CHANGE_PLANS)
  }

  if (!userProfile) return <Loader />

  return (
    <>
      {view !== MembershipView.PLAN_DETAILS && !isNoPlan && (
        <Button sx={{ mb: 2, p: 0 }} onClick={onClick} variant="text">
          <ArrowBack sx={{ mr: 1 }} /> <strong>Back</strong>
        </Button>
      )}
      <Typography sx={{ mb: 1 }}>
        <strong>{typography}</strong>
      </Typography>
      {/* Render the major component */}
      {view === MembershipView.PLAN_DETAILS && (
        <PlanDetails userProfile={userProfile} loading={loading} openChangePlan={openChangePlan} recurlySubscription={recurlySubscription} />
      )}
      {view === MembershipView.CHANGE_PLANS && <Plans plan={plan} planCycle={planCycle} changePlan pendingPlan={pendingPlan} />}
    </>
  )
}
