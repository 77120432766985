import { useLazyQuery } from '@apollo/client'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { Box, Button, Grid, LinearProgress, Typography, linearProgressClasses, styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { useFlag } from '@unleash/proxy-client-react'
import { useState } from 'react'
import config from '../../../../../config'
import { HostedLoginTokenDocument, RecurlySubscription, UserProfile } from '../../../../../graphql/generated'
import formatBytes from '../../../../../utils/formatBytes'
import logger from '../../../../../utils/logger'
import useGAEvent from '../../../../auth/hooks/useGAEvent'
import useAlerts from '../../../hooks/useAlerts'
import CancelUpgradeModal from '../modals/CanelUpgradeModal'
import { CANCELPBCONTENT, CANCELPBLINK, CANCELPBTITLE, cancellationUrls, getPlanDisplayName } from '../utils/constants'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5B62FF'
  }
}))

interface Props {
  userProfile: UserProfile
  loading: boolean
  openChangePlan: () => void
  recurlySubscription?: RecurlySubscription
}

export default function PlanDetails({ userProfile, loading, openChangePlan, recurlySubscription }: Readonly<Props>) {
  const { createAlert } = useAlerts()
  const [fetchHostedLoginTokenAndRedirect] = useLazyQuery(HostedLoginTokenDocument, {
    onCompleted: (data) => {
      window.open(`${config.appUrl.recurly.editPayment}${data.subscriptions.hostedLoginToken}`, '_blank')
    }
  })
  const isCounterEnabled = false
  const totalSize = userProfile?.counters?.totalSize
  const storageUsed = totalSize ?? 0
  const storageAvailable = userProfile?.plan?.storage_limit ?? 0
  const percentageUsed = storageAvailable === -1 ? 0 : Math.min(100, (storageUsed / storageAvailable) * 100)
  const pendingPlan = getPlanDisplayName(recurlySubscription?.pendingChange?.plan?.code ?? 'no-plan')

  const vindiciaInMaintenanceMode = useFlag('vindiciaInMaintenanceMode')
  const paddleInMaintenanceMode = useFlag('paddleInMaintenanceMode')

  const trialPlan = userProfile?.subscription?.isTrial
  const paymentMethod = userProfile.subscription?.subscription?.account ?? 'Free'
  const isApple = ['itunes', 'apple'].includes(paymentMethod.toLowerCase())
  const isPaypal = ['paypal', 'paypal_2', 'vindicia'].includes(paymentMethod.toLowerCase()) && !vindiciaInMaintenanceMode
  const isVindicia = ['vindicia'].includes(paymentMethod.toLowerCase()) && !vindiciaInMaintenanceMode
  const isPaddle = ['paddle'].includes(paymentMethod.toLowerCase()) && !paddleInMaintenanceMode
  const isRecurly = ['recurly'].includes(paymentMethod.toLowerCase())

  const vindiciaUnderMaintenance = vindiciaInMaintenanceMode && ['paypal', 'paypal_2', 'vindicia'].includes(paymentMethod.toLowerCase())
  const paddleUnderMaintenance = paddleInMaintenanceMode && ['paddle'].includes(paymentMethod.toLowerCase())

  const expirationDate = new Date(userProfile?.subscription?.expirationDate ?? '')
  const firstBillingDate = new Date(userProfile?.subscription?.firstBillingDate ?? '')
  const formattedExpirationDate = trialPlan
    ? `${expirationDate.getMonth() + 1}/${firstBillingDate.getDate()}/${firstBillingDate.getFullYear()}`
    : `${expirationDate.getMonth() + 1}/${expirationDate.getDate()}/${expirationDate.getFullYear()}`
  const storageUsedDisplay = formatBytes(storageUsed)
  let storageAvailableDisplay: JSX.Element | string
  const { trackEvent } = useGAEvent()

  switch (storageAvailable) {
    case -1:
      storageAvailableDisplay = <AllInclusiveIcon fontSize="medium" sx={{ ml: 1, mr: 1 }} />
      break
    case 0:
      storageAvailableDisplay = 'No Storage'
      break
    default:
      storageAvailableDisplay = formatBytes(storageAvailable)
      break
  }

  const isRecurring = userProfile?.subscription?.recurring

  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
  const [paymentTitle, setPaymentTitle] = useState<string>()
  const [paymentLink, setPaymentLink] = useState<string>()
  const [paymentContent, setPaymentContent] = useState<string>()
  const [supportUrl, setSupportUrl] = useState<string>()

  const planName = getPlanDisplayName(userProfile?.plan?.plan_name ?? 'no-plan')
  const getStorageDisplay = () => {
    const storageLimit = userProfile.plan?.storage_limit

    if (storageLimit === -1) {
      return 'Unlimited Storage'
    }
    if (storageLimit !== null) {
      return `${formatBytes(storageLimit)} Compression Free Storage`
    }
    return 'Storage limit not set'
  }

  const onCancelModalClose = () => {
    setIsCancelModalOpen(false)
  }

  const handleUpdatePayment = async () => {
    if (isRecurly) {
      const subscriptionId = userProfile?.subscription?.subscription?.id

      if (!userProfile || !subscriptionId) {
        createAlert('There was an issue fetching your profile. Please try again later.', 'error')
        return
      }

      await fetchHostedLoginTokenAndRedirect({ variables: { subscriptionId } })
    } else {
      window.open(config.appUrl.billing, '_blank')
    }
  }

  const handleUpdatePaypalPayment = () => {
    window.open(config.appUrl.paypal, '_blank')
  }

  const handleCancelPlanClick = () => {
    const planIdentifier = userProfile?.plan?.plan_name.toLowerCase()
    const redirectUrl = cancellationUrls[planIdentifier as keyof typeof cancellationUrls]

    if (redirectUrl) {
      setIsCancelModalOpen(true)
      setPaymentTitle(CANCELPBTITLE.default)
      setPaymentContent(CANCELPBCONTENT.default)
      setPaymentLink(CANCELPBLINK)
      setSupportUrl(redirectUrl)

      trackEvent('cancel_membership_clicked', {
        metric: 'cancel_membership_clicked',
        username: userProfile?.id,
        plan: userProfile?.plan?.plan_name,
        recurring: userProfile?.subscription?.recurring
      })
    } else {
      logger.error('No redirect URL found for the current plan:', planIdentifier)
    }
  }

  return (
    <>
      <div>
        <Typography variant="h6">{planName} Plan</Typography>
        {loading && <LinearProgress sx={{ mt: 3, maxWidth: '200px' }} />}
        {storageAvailable === -1 && <Typography style={{ marginTop: '10px' }}>Unlimited Storage</Typography>}
        {storageAvailable > 0 && isCounterEnabled && (
          <>
            <BorderLinearProgress sx={{ mt: 3, maxWidth: '200px' }} variant="determinate" value={percentageUsed} />
            <Typography sx={{ mt: 1, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              Storage: {storageUsedDisplay} / {storageAvailableDisplay}
            </Typography>
          </>
        )}
        {storageAvailable === 0 && <Typography sx={{ mt: 1, fontWeight: 600 }}>{storageAvailableDisplay}</Typography>}

        <Grid container style={{ margin: '20px 0' }}>
          <Grid item xs={12} md={4} lg={3}>
            <ul style={{ margin: 0 }}>
              {userProfile.plan?.plan_name !== 'no-plan' ? <li>{getStorageDisplay()}</li> : <li>You have no features associated with your plan.</li>}
              {userProfile.plan?.is_sharing_plan && <li>Unlimited Group & Personal Buckets</li>}
              {userProfile.plan?.plan_name !== 'no-plan' && !userProfile.plan?.is_sharing_plan && <li>Unlimited Personal Buckets</li>}
              {userProfile.plan?.is_modify_albums_enabled && <li>Advanced Album Organization</li>}
            </ul>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <ul style={{ margin: 0 }}>
              {userProfile.plan?.plan_name !== 'no-plan' && <li>Free Facebook Integration</li>}
              {userProfile.plan?.is_image_editor_enabled && <li>Photo Image Editing</li>}
              {userProfile.plan?.is_video_player_enabled && <li>Video Playback</li>}
            </ul>
          </Grid>
        </Grid>
        <Typography>
          {isRecurring ? 'Renews on ' : 'Ends on '}
          <strong>{formattedExpirationDate}</strong>
          {pendingPlan !== 'No Plan' ? (
            <>
              {' '}
              as the <strong>{pendingPlan}</strong>.
            </>
          ) : (
            '.'
          )}
        </Typography>
        {(vindiciaUnderMaintenance || paddleUnderMaintenance) && (
          <Grid alignItems="center">
            <Typography style={{ paddingTop: 10, color: '#880808', maxWidth: 700 }}>
              We are currently performing maintenance on our payment system. Please check back later, or with the{' '}
              <Link
                to="https://support.photobucket.com/hc/en-us"
                target="_blank"
                style={{
                  color: '#0056D2',
                  fontWeight: '600',
                  textDecoration: 'none'
                }}
              >
                Member Engagement Team
              </Link>{' '}
              to update your payment method or cancel your subscription.
            </Typography>
          </Grid>
        )}
        {isPaypal && (
          <Grid alignItems="center">
            <Button onClick={handleUpdatePaypalPayment} sx={{ display: 'block', mb: 2, p: 0 }} variant="text">
              Visit PayPal.com to view or update your payment method
            </Button>
          </Grid>
        )}
        {isApple && (
          <Typography style={{ paddingTop: 10 }}>
            Your subscription was started via Apple. To update your status or payment method, visit your iCloud subscriptions on your Apple device
          </Typography>
        )}
        {isPaddle && (
          <Button onClick={handleUpdatePayment} sx={{ display: 'block', mb: 2, p: 0 }} variant="text">
            View or update your payment method
          </Button>
        )}
        {isRecurly && (
          <Button data-testid="view-or-update-payment-method" onClick={handleUpdatePayment} sx={{ display: 'block', mb: 2, p: 0 }} variant="text">
            View or update your payment method
          </Button>
        )}
        {(isRecurly || isVindicia || isPaddle) && (
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
            <Button
              color="error"
              variant="outlined"
              data-testid="cancel-membership"
              sx={{ width: 'fit-content' }}
              onClick={handleCancelPlanClick}
              disabled={Boolean(userProfile?.subscription?.isCanceled)}
              data-pendo="cancel-membership--step-01"
            >
              Cancel Membership
            </Button>
            {!userProfile?.subscription?.isCanceled && (
              <Button
                data-testid="upgrade-or-change-plan"
                onClick={openChangePlan}
                variant="contained"
                sx={{ width: 'fit-content' }}
                data-pendo="upgrade-membership--step-01"
              >
                Upgrade or Change Plan
              </Button>
            )}
            {userProfile?.subscription?.isCanceled && (
              <Button onClick={openChangePlan} variant="contained" sx={{ width: 'fit-content' }} data-pendo="resubscribe-membership--step-01">
                Resubscribe
              </Button>
            )}
          </Box>
        )}
      </div>
      <CancelUpgradeModal
        isOpen={isCancelModalOpen}
        onClose={onCancelModalClose}
        title={paymentTitle}
        content={paymentContent}
        link={paymentLink}
        upgradeLink={openChangePlan}
        url={supportUrl}
        userProfile={userProfile}
      />
    </>
  )
}
