import { useQuery } from '@apollo/client'
import { Box, Button, Card, CardActions, Grid, styled, Typography } from '@mui/material'
import ExternalLink from '@mui/material/Link'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import config from '../../../../../config'
import { SubscriptionDocument, UserProfileDocument } from '../../../../../graphql/generated'
import useGAEvent from '../../../../auth/hooks/useGAEvent'
import UpgradeChangeModal from '../../../../buckets/components/settings/modals/UpgradeChangeModal'
import FeatureList from './FeatureList'

const StyledGrid = styled(Grid)(() => ({
  justifyContent: 'center',
  marginTop: '10px'
}))

const StyledCard = styled(Card)(() => ({
  position: 'relative',
  borderRadius: '30px',
  marginTop: '20px',
  textAlign: 'center',
  border: '1px solid #DADADA',
  boxShadow: '0px 5.59441px 151.049px rgba(227, 227, 227, 0.25)',
  flex: 1,
  overflow: 'visible'
}))

const CardHead = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: 1,
  marginBottom: '10px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.75rem',
    marginBottom: '5px',
    marginTop: '75px'
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: '80px'
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '80px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.75rem'
  }
}))

const CardStorage = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1,
  textTransform: 'none',
  [theme.breakpoints.up('sm')]: {
    padding: '0 10px',
    fontSize: '0.75rem',
    lineHeight: 1.25
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem'
  }
}))

const CardPrice = styled(Typography)(({ theme }) => ({
  color: '#0b0b0b',
  fontSize: '5.5rem',
  fontWeight: 600,
  lineHeight: 1,
  marginTop: '20px',
  marginBottom: '10px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '4.25rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4.75rem'
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '25px',
    fontSize: '5.5rem'
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: '30px',
    fontSize: '7.5rem'
  }
}))

const CardTerm = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1,
  margin: '5px 0 0',
  marginBottom: '0',
  [theme.breakpoints.up('lg')]: {
    margin: '10px 0',
    fontSize: '1.25rem'
  },
  [theme.breakpoints.up('xl')]: {
    margin: '10px 0 20px',
    fontSize: '1.5rem'
  }
}))

const PaddingActions = styled(CardActions)(({ theme }) => ({
  padding: '20px 16px',
  [theme.breakpoints.up('sm')]: {
    padding: '20px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px 20px'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '20px 35px'
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  borderRadius: '86px',
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
  fontWeight: 500,
  textTransform: 'none',
  margin: '0 auto',
  padding: '8px 20px',
  width: '80%',
  '&:hover': {
    background: 'linear-gradient(175deg, #02E39F 5.3%, #202CD3 102.34%)',
    color: theme.palette.primary.contrastText
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
    padding: '8px 15px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.875rem',
    padding: '5px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '5px 5px',
    fontSize: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '10px 20px',
    fontSize: '1.25rem'
  }
}))

const CurrentPlan = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 1.75,
  color: theme.palette.primary.contrastText,
  background: '#658CBB',
  borderRadius: '86px',
  fontWeight: 500,
  margin: '0 auto',
  padding: '8px 20px',
  width: '80%',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
    padding: '8px 15px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    padding: '5px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '5px',
    fontSize: '1.275rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '10px 20px',
    fontSize: '1.5rem'
  }
}))

const CardSavings = styled(Typography)(({ theme }) => ({
  marginBottom: 0,
  marginTop: 0,
  padding: '18px 16px 20px',
  color: '#2b2b2b',
  fontSize: '0.875rem',
  fontWeight: 300,
  lineHeight: 1.25,
  '& span': {
    fontWeight: '600',
    color: theme.palette.primary.main
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px 20px',
    fontSize: '1.125rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '40px 50px 40px',
    fontSize: '1.5rem'
  }
}))

const CurrentPlanSelected = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  color: 'black',
  background: '#EFEFFF',
  borderRadius: '10px',
  border: '1px solid blue',
  fontWeight: 300,
  margin: '0 auto',
  padding: '8px 35px',
  position: 'absolute',
  zIndex: 1,
  width: '90%',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1rem',
    padding: '8px 20px',
    top: '25px',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '5px 30px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '.75rem',
    top: '30px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    top: '30px'
  }
}))

const Recommended = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  color: 'black',
  background: '#00DB98',
  borderRadius: '50px',
  fontWeight: 500,
  margin: '0 auto',
  padding: '8px 35px',
  position: 'absolute',
  zIndex: 1,
  width: '70%',
  maxWidth: '300px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1rem',
    padding: '8px 20px',
    top: '-22px',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '8px 30px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '8px 10px',
    top: '-20px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '10px 20px'
  },
  [theme.breakpoints.up('xl')]: {
    top: '-25px'
  }
}))

const BlackLink = styled(ExternalLink)(({ theme }) => ({
  marginTop: 12,
  fontWeight: 600,
  display: 'block',
  color: 'primary',
  textDecoration: 'none',
  [theme.breakpoints.down('sm')]: {
    // targeting mobile devices
    margin: 3
  },
  '&:hover': {
    cursor: 'pointer'
  }
}))

interface YearlyProps {
  plan?: string
  isYearly?: boolean
  changePlan?: boolean
  pendingPlan?: string
}

interface MonthlyProps {
  plan?: string
  isMonthly?: boolean
  changePlan?: boolean
  pendingPlan?: string
}

type PlanId = 'storage' | 'sharing' | 'expert'

const CHANGETITLE = 'Change Your Plan'
const UPGRADETITLE = 'Upgrade Your Plan'
const DOWNGRADETITLE = 'Downgrade Your Plan'
const CHANGECONTENT = 'You are about to change your plan to from '
const UPGRADECONTENT = 'Great choice! With this updgrade, you will be gaining access to essential features like '
const DOWNGRADECONTENT =
  "We're sorry to see that you'd like to downgrade your plan. While we understand, you should be aware that you will be losing access to essential features like "
const HOSTINGFEATURE = 'embed links for hosting'
const SHARINGFEATURE = 'sharing, photo editing, and video playback'

const determinePlanId = (planOrTrial: string | undefined) => {
  if (planOrTrial === 'trial') {
    return '3e6d9700-24cf-4a70-9b22-ece1fab471b1'
  }
  return 'f4041bef-924b-451b-88dc-3a8aba90ec5e'
}

type BillingType = 'monthly' | 'yearly' | 'trial'

function BillingURL(id: string, type: BillingType) {
  const data = {
    id,
    type
  }

  const url = config.appUrl.billing
  const querystring = new URLSearchParams(data).toString()
  return [url, querystring].join('?')
}

const freeTrialPathnameBlacklist = ['/subscription/deactivated', '/subscription/expired', '/subscription/predeactivated']
const pathnameAllowsFreeTrial = (pathname: string) =>
  !freeTrialPathnameBlacklist.some((blacklistedPathname) => pathname.includes(blacklistedPathname))

function MonthlyPlans({ plan, isMonthly, changePlan, pendingPlan }: Readonly<MonthlyProps>) {
  const userProfile = useQuery(UserProfileDocument, { nextFetchPolicy: 'cache-and-network' })
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const { pathname } = location
  const subscriptionPage = pathname.includes('/subscription')

  const paymentMethod = userProfile.data?.userProfile?.subscription?.subscription?.account ?? 'Free'
  const isRecurly = ['recurly'].includes(paymentMethod.toLowerCase())
  const membershipPage = pathname.includes('/settings')
  const subscriptionId = userProfile.data?.userProfile?.subscription?.subscription?.id
  const subscription = useQuery(SubscriptionDocument, { skip: !subscriptionId && !isRecurly, variables: { subscriptionId: subscriptionId! } })

  const currentTermEndsAt = subscription.data?.subscriptions?.subscription?.recurlySubscription?.currentTermEndsAt

  const formattedDate = currentTermEndsAt
    ? new Date(currentTermEndsAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    : 'date not available'

  // Check for promo or coupon query params
  const promo = params.get('promo')
  const coupon = params.get('coupon')
  const couponQuery = promo || coupon ? `&coupon=${promo ?? coupon}` : ''
  const subscriptionNextPageUrl = subscriptionPage ? '/subscription/payment' : '/auth/register'
  const { trackEvent } = useGAEvent()

  const [planName, setPlanName] = useState<string>()
  const [price, setPrice] = useState<string>()
  const [contactUs, setContactUs] = useState<boolean>(false)
  const [upgradeTitle, setUpgradeTitle] = useState<string>()
  const [upgradeContent, setUpgradeContent] = useState<string>()
  const [boldContent, setBoldContent] = useState<string>()
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false)
  const onUpgradeModalClose = () => {
    setIsUpgradeModalOpen(false)
  }

  const isPlanAnUpgrade = (selectedPlan: string) => {
    const currentPlan = userProfile.data?.userProfile?.subscription?.planName?.split('-')[0] ?? 'Free'

    if ((selectedPlan === 'expert' && currentPlan === 'expert') || selectedPlan === currentPlan) {
      setUpgradeTitle(CHANGETITLE)
      setUpgradeContent(CHANGECONTENT)
      setBoldContent(`annual to monthly`)
      setContactUs(false)
    } else if (selectedPlan === 'expert' && currentPlan !== 'expert') {
      setUpgradeTitle(UPGRADETITLE)
      setUpgradeContent(UPGRADECONTENT)
      setBoldContent(HOSTINGFEATURE)
      setContactUs(false)
    } else if (selectedPlan === 'sharing' && currentPlan === 'storage') {
      setUpgradeTitle(UPGRADETITLE)
      setUpgradeContent(UPGRADECONTENT)
      setBoldContent(SHARINGFEATURE)
      setContactUs(false)
    } else if (selectedPlan !== 'expert' && currentPlan === 'expert') {
      setUpgradeTitle(DOWNGRADETITLE)
      setUpgradeContent(DOWNGRADECONTENT)
      setBoldContent(HOSTINGFEATURE)
      setContactUs(true)
    } else {
      setUpgradeTitle(DOWNGRADETITLE)
      setUpgradeContent(DOWNGRADECONTENT)
      setBoldContent(SHARINGFEATURE)
      setContactUs(true)
    }
  }

  const onClickPlan = (id: PlanId, cycle: string, selectedPrice: number, itemId: string) => {
    trackEvent('select_item', {
      ecommerce: {
        item_list_id: itemId,
        item_list_name: `${id}-${cycle}`,
        items: [{ item_id: `${id}_${cycle}`, item_name: `${id} ${cycle}`, selectedPrice }]
      }
    })
    if (isRecurly && changePlan) {
      isPlanAnUpgrade(id)
      setIsUpgradeModalOpen(true)
      setPlanName(id)
      setPrice(selectedPrice.toString())
      return
    }
    if (plan === 'no-plan') {
      const url = `subscription/payment?plan_name=${id}&billing_cycle=${cycle}${couponQuery}`
      window.location.href = url
      return
    }
    // remove once recurly is 100% implemented and paddle is migrated to recurly
    const billingPlanId = id === 'expert' ? 'premium' : id
    const url =
      !membershipPage || subscriptionPage
        ? `${subscriptionNextPageUrl}?plan_name=${id}&billing_cycle=${cycle}${couponQuery}`
        : BillingURL(billingPlanId, 'monthly')
    window.location.href = url
  }

  // capture event for page view
  useEffectOnce(() => {
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_id: 'monthly plans',
        item_list_name: 'monthly plans',
        items: [
          {
            item_id: '6f0ba9cb-7dcf-4f89-9fda-ba430662b006',
            item_name: 'storage_monthly',
            price: 5
          },
          {
            item_id: 'f4041bef-924b-451b-88dc-3a8aba90ec5e',
            item_name: 'sharing_monthly',
            price: 8
          },
          {
            item_id: '3e6d9700-24cf-4a70-9b22-ece1fab471b1',
            item_name: 'sharing_trial',
            price: 0
          },
          {
            item_id: 'ce2dd8aa-69c7-47f4-ae4d-9f09e094f13c',
            item_name: 'hosting_monthly',
            price: 13
          }
        ]
      }
    })
  })

  let planNameOrTrial: 'monthly' | 'annual' | 'trial'
  if (plan === undefined && pathnameAllowsFreeTrial(pathname)) {
    planNameOrTrial = 'trial'
  } else if (plan === 'no-plan' && pathnameAllowsFreeTrial(pathname)) {
    planNameOrTrial = 'trial'
  } else if (subscriptionPage && pathnameAllowsFreeTrial(pathname)) {
    planNameOrTrial = 'trial'
  } else {
    planNameOrTrial = 'monthly'
  }

  return (
    <>
      <StyledGrid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <StyledCard>
            {subscriptionPage && plan === 'storage' && isMonthly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
            <CardHead>My Bucket</CardHead>
            <CardStorage>1 TB of basic storage</CardStorage>
            <CardPrice>$5</CardPrice>
            <CardTerm>per month</CardTerm>
            {((!subscriptionPage && (plan === 'storage' || plan === 'trial')) || pendingPlan === 'storage-monthly') && isMonthly ? (
              <PaddingActions>
                <CurrentPlan>{pendingPlan === 'storage-monthly' ? 'Pending Plan' : 'Current Plan'}</CurrentPlan>
              </PaddingActions>
            ) : (
              <PaddingActions>
                <StyledButton
                  data-test="plans-monthly-storage"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    onClickPlan('storage', 'monthly', 5, '6f0ba9cb-7dcf-4f89-9fda-ba430662b006')
                  }}
                >
                  Purchase
                </StyledButton>
              </PaddingActions>
            )}
            <FeatureList plan="storage" />
          </StyledCard>
          {(!subscriptionPage && plan === 'storage' && !isMonthly) || pendingPlan === 'storage-annual' ? (
            <BlackLink>
              <Box data-testid="black-link-annual-storage-current" component="span" color="black">
                {pendingPlan === 'storage-annual' ? 'Pending Plan' : 'Current Plan'}
              </Box>
            </BlackLink>
          ) : (
            <BlackLink
              onClick={() => {
                onClickPlan('storage', 'annual', 50, '9901c53a-671a-41f2-9f4d-c22d013400bf')
              }}
            >
              <Box component="span">Save another $10 by paying yearly</Box>
            </BlackLink>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <StyledCard sx={{ border: '1px solid blue' }}>
            <Recommended>Most Popular</Recommended>
            {subscriptionPage && plan === 'sharing' && isMonthly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
            <CardHead>Group Buckets</CardHead>
            <CardStorage>1 TB of storage + sharing</CardStorage>
            <CardPrice>$8</CardPrice>
            <CardTerm>per month</CardTerm>
            {((!subscriptionPage && plan === 'sharing') || pendingPlan === 'sharing-monthly') && isMonthly ? (
              <PaddingActions>
                <CurrentPlan>{pendingPlan === 'sharing-monthly' ? 'Pending Plan' : 'Current Plan'}</CurrentPlan>
              </PaddingActions>
            ) : (
              <PaddingActions>
                <StyledButton
                  data-testid="plans-monthly-sharing"
                  fullWidth
                  variant="contained"
                  sx={{ background: 'linear-gradient(175deg, #202CD3 5.3%, #02E39F 102.34%)' }}
                  onClick={() => {
                    onClickPlan('sharing', planNameOrTrial, planNameOrTrial === 'trial' ? 0 : 8, determinePlanId(planNameOrTrial))
                  }}
                >
                  {planNameOrTrial === 'trial' ? 'Start 7-day Free Trial' : 'Purchase'}
                </StyledButton>
              </PaddingActions>
            )}
            <FeatureList plan="sharing" />
          </StyledCard>
          {(!subscriptionPage && plan === 'sharing' && !isMonthly) || pendingPlan === 'sharing-annual' ? (
            <BlackLink>
              <Box data-testid="black-link-annual-sharing-current" component="span" color="black">
                {pendingPlan === 'sharing-annual' ? 'Pending Plan' : 'Current Plan'}
              </Box>
            </BlackLink>
          ) : (
            <BlackLink
              onClick={() => {
                onClickPlan('sharing', 'annual', 80, '9901c53a-671a-41f2-9f4d-c22d013400bf')
              }}
            >
              <Box component="span">Save another $16 by paying yearly</Box>
            </BlackLink>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <StyledCard>
            {subscriptionPage && plan === 'expert' && isMonthly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
            <CardHead>Hosting</CardHead>
            <CardStorage>Embedded sharing</CardStorage>
            <CardPrice>$13</CardPrice>
            <CardTerm>per month</CardTerm>
            {((!subscriptionPage && plan === 'expert') || pendingPlan === 'expert-monthly') && isMonthly ? (
              <PaddingActions>
                <CurrentPlan>{pendingPlan === 'expert-monthly' ? 'Pending Plan' : 'Current Plan'}</CurrentPlan>
              </PaddingActions>
            ) : (
              <PaddingActions>
                <StyledButton
                  data-testid="plans-monthly-hosting"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    onClickPlan('expert', 'monthly', 13, 'ce2dd8aa-69c7-47f4-ae4d-9f09e094f13c')
                  }}
                >
                  Purchase
                </StyledButton>
              </PaddingActions>
            )}
            <FeatureList plan="expert" />
          </StyledCard>
          {(!subscriptionPage && plan === 'expert' && !isMonthly) || pendingPlan === 'expert-annual' ? (
            <BlackLink>
              <Box data-testid="black-link-annual-hosting-current" component="span" color="black">
                {pendingPlan === 'expert-annual' ? 'Pending Plan' : 'Current Plan'}
              </Box>
            </BlackLink>
          ) : (
            <BlackLink
              onClick={() => {
                onClickPlan('expert', 'annual', 140, '82ce8670-7abc-4fa4-8868-bce86e5da8ef')
              }}
            >
              <Box data-testid="black-link-annual-hosting" component="span">
                Save another $16 by paying yearly
              </Box>
            </BlackLink>
          )}
        </Grid>
      </StyledGrid>
      <UpgradeChangeModal
        isOpen={isUpgradeModalOpen}
        onClose={onUpgradeModalClose}
        title={upgradeTitle}
        content={upgradeContent}
        boldContent={boldContent}
        price={price}
        planName={planName ?? 'free'}
        billingCycle="monthly"
        contactUs={contactUs}
        currentTermEndsAt={formattedDate}
      />
    </>
  )
}

MonthlyPlans.defaultProps = {
  plan: undefined,
  isMonthly: false,
  changePlan: false,
  pendingPlan: undefined
}

function YearlyPlans({ plan, isYearly, changePlan, pendingPlan }: Readonly<YearlyProps>) {
  const userProfile = useQuery(UserProfileDocument, { nextFetchPolicy: 'cache-and-network' })
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const { pathname } = location
  const subscriptionPage = pathname.includes('/subscription')

  const paymentMethod = userProfile.data?.userProfile?.subscription?.subscription?.account ?? 'Free'
  const isRecurly = ['recurly'].includes(paymentMethod.toLowerCase())
  const membershipPage = pathname.includes('/settings')
  const subscriptionId = userProfile.data?.userProfile?.subscription?.subscription?.id
  const subscription = useQuery(SubscriptionDocument, { skip: !subscriptionId && !isRecurly, variables: { subscriptionId: subscriptionId! } })

  const currentTermEndsAt = subscription.data?.subscriptions?.subscription?.recurlySubscription?.currentTermEndsAt

  const formattedDate = currentTermEndsAt
    ? new Date(currentTermEndsAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    : 'date not available'

  // Check for promo or coupon query params
  const promo = params.get('promo')
  const coupon = params.get('coupon')
  const couponQuery = promo || coupon ? `&coupon=${promo ?? coupon}` : ''
  const subscriptionNextPageUrl = subscriptionPage ? '/subscription/payment' : '/auth/register'
  const { trackEvent } = useGAEvent()

  const [planName, setPlanName] = useState<string>()
  const [price, setPrice] = useState<string>()
  const [contactUs, setContactUs] = useState<boolean>(false)
  const [upgradeTitle, setUpgradeTitle] = useState<string>()
  const [upgradeContent, setUpgradeContent] = useState<string>()
  const [boldContent, setBoldContent] = useState<string>()
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false)
  const onUpgradeModalClose = () => {
    setIsUpgradeModalOpen(false)
  }

  const isPlanAnUpgrade = (selectedPlan: string) => {
    const currentPlan = userProfile.data?.userProfile?.subscription?.planName?.split('-')[0] ?? 'Free'

    if ((selectedPlan === 'expert' && currentPlan === 'expert') || selectedPlan === currentPlan) {
      setUpgradeTitle(CHANGETITLE)
      setUpgradeContent(CHANGECONTENT)
      setBoldContent(`monthly to annual`)
      setContactUs(false)
    } else if (selectedPlan === 'expert' && currentPlan !== 'expert') {
      setUpgradeTitle(UPGRADETITLE)
      setUpgradeContent(UPGRADECONTENT)
      setBoldContent(HOSTINGFEATURE)
      setContactUs(false)
    } else if (selectedPlan === 'sharing' && currentPlan === 'storage') {
      setUpgradeTitle(UPGRADETITLE)
      setUpgradeContent(UPGRADECONTENT)
      setBoldContent(SHARINGFEATURE)
      setContactUs(false)
    } else if (selectedPlan !== 'expert' && currentPlan === 'expert') {
      setUpgradeTitle(DOWNGRADETITLE)
      setUpgradeContent(DOWNGRADECONTENT)
      setBoldContent(HOSTINGFEATURE)
      setContactUs(true)
    } else {
      setUpgradeTitle(DOWNGRADETITLE)
      setUpgradeContent(DOWNGRADECONTENT)
      setBoldContent(SHARINGFEATURE)
      setContactUs(true)
    }
  }

  const onClickPlan = (id: 'storage' | 'sharing' | 'expert', cycle: string, selectedPrice: number, itemId: string) => {
    trackEvent('select_item', {
      ecommerce: {
        item_list_id: itemId,
        item_list_name: `${id}-${cycle}`,
        items: [{ item_id: `${id}_${cycle}`, item_name: `${id} ${cycle}`, selectedPrice }]
      }
    })
    if (isRecurly && changePlan) {
      isPlanAnUpgrade(id)
      setIsUpgradeModalOpen(true)
      setPlanName(id)
      setPrice(selectedPrice.toString())
      return
    }
    // remove once recurly is 100% implemented and paddle is migrated to recurly
    const billingPlanId = id === 'expert' ? 'premium' : id
    const url =
      !membershipPage || subscriptionPage
        ? `${subscriptionNextPageUrl}?plan_name=${id}&billing_cycle=${cycle}${couponQuery}`
        : BillingURL(billingPlanId, 'monthly')

    window.location.href = url
  }

  // capture event for page view
  useEffectOnce(() => {
    trackEvent('view_item_list', {
      ecommerce: {
        item_list_id: 'annual plans',
        item_list_name: 'annual plans',
        items: [
          {
            item_id: '9901c53a-671a-41f2-9f4d-c22d013400bf',
            item_name: 'storage_annual',
            price: 50
          },
          {
            item_id: '96cda310-2859-4f4c-bbc6-f6f4c5d09296',
            item_name: 'sharing_annual',
            price: 80
          },
          {
            item_id: '82ce8670-7abc-4fa4-8868-bce86e5da8ef',
            item_name: 'hosting_annual',
            price: 140
          }
        ]
      }
    })
  })

  return (
    <>
      <StyledGrid container spacing={2} display="flex" justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <StyledCard>
            {subscriptionPage && plan === 'storage' && isYearly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
            <CardHead>My Bucket</CardHead>
            <CardStorage>1 TB of basic storage</CardStorage>
            <CardPrice>$50</CardPrice>
            <CardTerm>per year</CardTerm>
            {(!subscriptionPage && plan === 'storage' && isYearly) || pendingPlan === 'storage-annual' ? (
              <PaddingActions>
                <CurrentPlan>{pendingPlan?.includes('storage') ? 'Pending Plan' : 'Current Plan'}</CurrentPlan>
              </PaddingActions>
            ) : (
              <PaddingActions>
                <StyledButton
                  data-test="plans-yearly-storage"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    onClickPlan('storage', 'annual', 50, '9901c53a-671a-41f2-9f4d-c22d013400bf')
                  }}
                >
                  Purchase
                </StyledButton>
              </PaddingActions>
            )}
            <FeatureList plan="storage" />
          </StyledCard>
          <CardSavings>
            You <Box component="span">save $10</Box> vs a monthly payment
          </CardSavings>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <StyledCard>
            {subscriptionPage && plan === 'sharing' && isYearly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
            <CardHead>Group Buckets</CardHead>
            <CardStorage>1 TB of storage + sharing</CardStorage>
            <CardPrice>$80</CardPrice>
            <CardTerm>per year</CardTerm>
            {(!subscriptionPage && plan === 'sharing' && isYearly) || pendingPlan === 'sharing-annual' ? (
              <PaddingActions>
                <CurrentPlan>{pendingPlan?.includes('sharing') ? 'Pending Plan' : 'Current Plan'}</CurrentPlan>
              </PaddingActions>
            ) : (
              <PaddingActions>
                <StyledButton
                  data-test="plans-yearly-sharing"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    onClickPlan('sharing', 'annual', 80, '96cda310-2859-4f4c-bbc6-f6f4c5d09296')
                  }}
                >
                  Purchase
                </StyledButton>
              </PaddingActions>
            )}
            <FeatureList plan="sharing" />
          </StyledCard>
          <CardSavings>
            You <Box component="span">save $16</Box> vs a monthly payment
          </CardSavings>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <StyledCard>
            {subscriptionPage && plan === 'expert' && isYearly && <CurrentPlanSelected>Currently selected plan.</CurrentPlanSelected>}
            <CardHead>Hosting</CardHead>
            <CardStorage>Embedded sharing</CardStorage>
            <CardPrice>$140</CardPrice>
            <CardTerm>per year</CardTerm>
            {(!subscriptionPage && plan === 'expert' && isYearly) || pendingPlan === 'expert-annual' ? (
              <PaddingActions>
                <CurrentPlan>{pendingPlan === 'expert-annual' ? 'Pending Plan' : 'Current Plan'}</CurrentPlan>
              </PaddingActions>
            ) : (
              <PaddingActions>
                <StyledButton
                  data-testid="plans-annual-hosting"
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    onClickPlan('expert', 'annual', 140, '82ce8670-7abc-4fa4-8868-bce86e5da8ef')
                  }}
                >
                  Purchase
                </StyledButton>
              </PaddingActions>
            )}
            <FeatureList plan="expert" />
          </StyledCard>
          <CardSavings>
            You <Box component="span">save $16</Box> vs a monthly payment
          </CardSavings>
        </Grid>
      </StyledGrid>
      <UpgradeChangeModal
        isOpen={isUpgradeModalOpen}
        onClose={onUpgradeModalClose}
        title={upgradeTitle}
        content={upgradeContent}
        boldContent={boldContent}
        contactUs={contactUs}
        price={price}
        planName={planName ?? 'free'}
        billingCycle="yearly"
        currentTermEndsAt={formattedDate}
      />
    </>
  )
}

YearlyPlans.defaultProps = {
  plan: undefined,
  isYearly: false,
  changePlan: false
}
export { MonthlyPlans, YearlyPlans }
